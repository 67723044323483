/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';

export default Route.extend({
  //region Dependencies
  enums: service(),
  store: service(),
  current: service(),
  settings: service(),
  //endregion

  //region Ember Hooks
  async model() {
    const getTemplateTypeIdFor = name => this.enums.findWhere('TEMPLATE_TYPE', { name }, 'id');

    const [
      privacyPolicyTemplates,
      rulesTemplates,
      termsOfServiceTemplates,
      designTokens,
      privacyPolicySetting,
      rulesSetting,
      termsOfServiceSetting,
    ] = await Promise.all([
      this.store.query('design-template', { designTemplateTypeId: getTemplateTypeIdFor('Privacy Policy') }),
      this.store.query('design-template', { designTemplateTypeId: getTemplateTypeIdFor('Rules') }),
      this.store.query('design-template', { designTemplateTypeId: getTemplateTypeIdFor('Terms of Service') }),
      this.store.findAll('design-token'),
      this.store.query('setting', { key: 'legal_default_one_time_template_privacy_policy' }),
      this.store.query('setting', { key: 'legal_default_one_time_template_rules' }),
      this.store.query('setting', { key: 'legal_default_one_time_template_terms_of_service' }),
    ]);

    return {
      privacyPolicyTemplates: privacyPolicyTemplates.toArray(),
      rulesTemplates: rulesTemplates.toArray(),
      termsOfServiceTemplates: termsOfServiceTemplates.toArray(),
      designTokens: designTokens.toArray(),
      oneTimeTemplateSettings: {
        privacyPolicy: privacyPolicySetting?.toArray(),
        rules: rulesSetting.toArray(),
        termsOfService: termsOfServiceSetting.toArray(),
      },
    };
  },
  //endregion

  //region Actions
  actions: {
    removeTemplate(template) {
      this.modelFor(this.routeName)[`${camelize(template.designTemplateType)}Templates`].removeObject(template);
    },

    addTemplate(template) {
      this.modelFor(this.routeName)[`${camelize(template.designTemplateType)}Templates`].addObject(template);
    },
    addDesignToken(designToken) {
      this.modelFor(this.routeName).designTokens?.addObject(designToken);
    },
    addTemplateSetting(setting, template) {
      this.modelFor(this.routeName).oneTimeTemplateSettings[`${camelize(template.designTemplateType)}`] = [
        ...this.modelFor(this.routeName).oneTimeTemplateSettings[`${camelize(template.designTemplateType)}`],
        setting,
      ];
    },
    removeTemplateSetting(setting, template) {
      this.modelFor(this.routeName).oneTimeTemplateSettings[`${camelize(template.designTemplateType)}`] = this.modelFor(
        this.routeName
      ).oneTimeTemplateSettings[`${camelize(template.designTemplateType)}`].filter(
        oneTimeSetting => oneTimeSetting.id != setting.id
      );
    },
  },
  //endregion
});
