import Service, { inject as service } from '@ember/service';
import RSVP from 'rsvp';
import type Store from '@ember-data/store';
import type SettingModel from 'partner/models/setting';
import type { Entries } from 'partner/utils/types';
import type CurrentService from './current';
import { isPresent } from '@ember/utils';

export type SettingsKey = keyof SettingsValues;

type SettingsValues = {
  allow_manual_additions: boolean;
  allow_ties: boolean;
  apply_to_all_winners: boolean;
  approved_entrants_for_voting: number;
  bottom_offset_iframe: number;
  category_sort_criteria: string;
  dips_url: string;
  enable_google_integration: string;
  promotion_results_display_type: number;
  promotion_results_display_type_after_completion: number;
  promotion_results_allow_view_without_participation: boolean;
  top_offset_iframe: number;
  use_static_iframe: boolean;
  use_email_validation: boolean;
  new_design_template: string;
  registration_type: number;
  kiosk_mode_delay: number;
  thanks_page_redirection_delay: number;
  thanks_page_redirect_url: string;
  tracking_pixel_enabled: boolean;
  tracking_pixel_head: string;
  tracking_pixel_body: string;
  default_theme_id: number;
  legal_default_one_time_template_privacy_policy: number;
  legal_default_one_time_template_rules: number;
  legal_default_one_time_template_terms_of_service: number;
};

export default class SettingsService extends Service {
  @service declare store: Store;
  @service declare current: CurrentService;

  private cache: Map<SettingsKey, SettingModel> = new Map();
  private uiTexts: SettingModel[] = [];

  getFor(key: SettingsKey) {
    const setting = this.cache.get(key);

    if (!setting) {
      throw new Error(
        `SettingsService.getValueFor("${key}") called before preload(). Make sure to preload("${key}") first.`
      );
    }

    return setting;
  }

  getValueFor<K extends SettingsKey>(key: K): SettingsValues[K] {
    return this.getFor(key).value;
  }

  async preload(keyOrKeys: SettingsKey | SettingsKey[], { force } = { force: false }) {
    const keys = Array.isArray(keyOrKeys) ? keyOrKeys : [keyOrKeys];

    return RSVP.all(
      keys.map(async key => {
        if (!force && this.cache.has(key)) return;

        this.cache.set(key, await this.store.queryRecord('setting', { key }));
      })
    );
  }

  async getUiTexts() {
    if (isPresent(this.uiTexts))
      return this.uiTexts.filterBy('languageId', this.current.organizationPromotion.defaultLanguageId);
    this.uiTexts = (await this.store.query('setting', { category: 'UI_Text' })).toArray();
    return this.uiTexts.filterBy('languageId', this.current.organizationPromotion.defaultLanguageId);
  }

  /**
   * Useful when testing. You should call `preload()` for all other cases.
   */
  seed(values: Partial<SettingsValues>) {
    for (const [key, value] of Object.entries(values) as Entries<SettingsValues>) {
      this.cache.set(key as SettingsKey, { value } as SettingModel);
    }
  }

  seedUiTexts(settings: SettingModel[]) {
    this.uiTexts = [...settings];
  }

  reset() {
    this.cache.clear();
  }
}
